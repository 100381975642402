import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import Articles from "../../articles/views/Articles.vue";
import Bookings from "../../bookings/views/Bookings.vue";
import ConfigBooking from "../../bookings/views/ConfigBooking.vue";
import DbProductsTable from "../../db-products/views/DbProductsTable.vue";
import Medias from "../../medias/views/Medias.vue";
import Menus from "../../menus/views/Menus.vue";
import { store } from "../store";
import { Getters } from "../store/getters";
import Admin from "../views/Admin.vue";
import Home from "../views/Home.vue";
import Index from "../views/Index.vue";
import Login from "../views/Login.vue";
import ResetPassword from "../views/ResetPassword.vue";

Vue.use(VueRouter);

function beforeEnter(to: Route, from: Route, next: NavigationGuardNext) {
  if (!store.getters[`core/${Getters.SITE}`]) {
    next("/");
  } else {
    next();
  }
}

function isAdmin(to: Route, from: Route, next: NavigationGuardNext) {
  if (!store.getters[`core/${Getters.IS_ADMIN}`]) {
    next("/");
  } else {
    next();
  }
}

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: Index,
    children: [
      {
        path: "",
        name: "Home.default",
        component: Home
      },
      {
        path: "menus",
        name: "Home.menus",
        component: Menus,
        beforeEnter
      },
      {
        path: "produits",
        name: "Home.products",
        component: DbProductsTable,
        beforeEnter: isAdmin
      },
      {
        path: "articles",
        name: "Home.articles",
        component: Articles,
        beforeEnter
      },
      {
        path: "medias",
        name: "Home.medias",
        component: Medias,
        beforeEnter
      },
      {
        path: "reservations",
        name: "Home.bookings",
        component: Bookings,
        beforeEnter
      },
      {
        path: "reservations/configuration",
        name: "Home.bookingConfiguration",
        component: ConfigBooking,
        beforeEnter
      }
    ]
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin
  },
  {
    path: "/resetPassword/:token",
    name: "ResetPassword",
    component: ResetPassword
  },
  {
    path: "/*",
    redirect: "/"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
