import { Actions } from "@/shared/core/store/actions";
import { Getters } from "@/shared/core/store/getters";
import { Mutations } from "@/shared/core/store/mutations";
import { Booking } from "../model/Booking";
import { BookingConfiguration } from "../model/BookingConfiguration";
import { BookingState } from "../model/BookingState";
import { bookingsService } from "../services/bookings.service";

const bookingState: BookingState = {
  bookings: [],
  bookingConfiguration: null,
};

export const booking = {
  namespaced: true,
  state: bookingState,
  mutations: {
    [Mutations.SET_BOOKINGS]: (state: BookingState, bookings: Booking[]) => {
      state.bookings = bookings;
    },
    [Mutations.SET_BOOKING_CONFIGURATION]: (
      state: BookingState,
      bookingConfiguration: BookingConfiguration
    ) => {
      state.bookingConfiguration = bookingConfiguration;
    },
  },
  actions: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    [Actions.SET_BOOKINGS]: async ({ commit }: { commit: Function }, customerId: string) => {
      commit(Mutations.SET_BOOKINGS, await bookingsService.getBookings(customerId));
    },
    [Actions.SET_BOOKING_CONFIGURATION]: async (
      // eslint-disable-next-line @typescript-eslint/ban-types
      { commit }: { commit: Function },
      customerId: string
    ) => {
      commit(
        Mutations.SET_BOOKING_CONFIGURATION,
        (await bookingsService.getBookingConfiguration(customerId)) || null
      );
    },
  },
  getters: {
    [Getters.BOOKINGS]: (state: BookingState): Booking[] => state.bookings,
    [Getters.BOOKING_CONFIGURATION]: (state: BookingState): BookingConfiguration | null =>
      state.bookingConfiguration,
  },
};
