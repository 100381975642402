/* eslint-disable @typescript-eslint/ban-types */
import Vue from "vue";
import { AUTH_TYPE } from "../model/AuthType";
import Site from "../model/Site";
import SiteInList from "../model/SiteInList";
import { State } from "../model/State";
import { User } from "../model/User";
import { USER_ROLE } from "../model/UserRole";
import { siteService } from "../services/site.service";
import { Actions } from "./actions";
import { Getters } from "./getters";
import { Mutations } from "./mutations";

export const coreState: State = {
  drawer: false,
  loaders: 0,
  list: [],
  site: null
};

export const core = {
  state: coreState,
  namespaced: true,
  actions: {
    [Actions.SET_LIST]: (
      { commit, dispatch }: { commit: Function; dispatch: Function },
      newList: SiteInList[]
    ) => {
      commit(`core/${Mutations.SET_LIST}`, newList, { root: true });
      if (newList.length === 1) {
        dispatch(`core/${Actions.SELECT_SITE}`, newList[0].customerId, {
          root: true
        });
      }
    },
    [Actions.SELECT_SITE]: async (
      { commit, dispatch }: { commit: Function; dispatch: Function },
      customerId: string | null
    ) => {
      if (customerId) {
        const site = await siteService.getSite(customerId);
        const customer = process.env.VUE_APP_CUSTOMER;
        if (site) {
          commit(`core/${Mutations.SET_SITE}`, site, { root: true });
          if ((await import(`../../../customers/${customer}/config`)).MENUS_MODULE_ENABLED) {
            dispatch(`menu/${Actions.SET_MENUS}`, customerId, { root: true });
            commit(`menu/${Mutations.SELECT_SERVICE}`, null, { root: true });
            commit(`menu/${Mutations.SET_ZONE}`, null, { root: true });
            commit(`menu/${Mutations.SELECT_DATE}`, null, { root: true });
          }
          if ((await import(`../../../customers/${customer}/config`)).MENUS_MODULE_ENABLED) {
            dispatch(`article/${Actions.SET_ARTICLES}`, customerId, { root: true });
          }
          if ((await import(`../../../customers/${customer}/config`)).MEDIAS_MODULE_ENABLED) {
            dispatch(`media/${Actions.SET_MEDIAS}`, customerId, { root: true });
          }
          if (site.enableBooking) {
            dispatch(`booking/${Actions.SET_BOOKING_CONFIGURATION}`, customerId, {
              root: true
            });
            dispatch(`booking/${Actions.SET_BOOKINGS}`, customerId, {
              root: true
            });
          }
        }
      } else {
        commit(`core/${Mutations.SET_SITE}`, null, { root: true });
        commit(`article/${Mutations.SET_ARTICLES}`, [], { root: true });
        commit(`booking/${Mutations.SET_BOOKING_CONFIGURATION}`, null, { root: true });
        commit(`booking/${Mutations.SET_BOOKINGS}`, [], { root: true });
        commit(`menu/${Mutations.SET_MENUS}`, [], { root: true });
        commit(`media/${Mutations.SET_MEDIAS}`, [], { root: true });
      }
    }
  },
  mutations: {
    [Mutations.SET_USER]: (state: State, user: User) => {
      Vue.set(state, "user", user);
    },
    [Mutations.START_LOADING]: (state: State) => {
      state.loaders += 1;
    },
    [Mutations.RELEASE_LOADING]: (state: State) => {
      if (state.loaders > 0)
        setTimeout(() => {
          state.loaders -= 1;
        }, 500);
    },
    [Mutations.SET_LIST]: (state: State, newList: SiteInList[]) => {
      state.list = newList;
    },
    [Mutations.SET_SITE]: (state: State, newSite: Site | null) => {
      state.site = newSite;
    },
    [Mutations.SET_DRAWER]: (state: State, newDrawerValue: boolean) => {
      state.drawer = newDrawerValue;
    }
  },
  getters: {
    [Getters.LOADING]: (state: State) => state.loaders > 0,
    [Getters.SELECTED]: (state: State) => {
      return state.site !== null;
    },
    [Getters.SITE]: (state: State) => state.site,
    [Getters.ENABLE_I18N]: (state: State) => state.site?.enableI18n,
    [Getters.IS_OAUTH]: (state: State): boolean => state.user?.auth_type === AUTH_TYPE.BEARER,
    [Getters.HAS_SITES]: (state: State): boolean =>
      state.user !== undefined && state.user.sites.length > 0,
    [Getters.IS_SUPERADMIN]: (state: State) =>
      state.user?.roles && state.user.roles.includes(USER_ROLE.SUPERADMIN),
    [Getters.IS_ADMIN]: (state: State) =>
      state.user?.roles && state.user.roles.includes(USER_ROLE.ADMIN)
  }
};
