
import Component from "vue-class-component";
import _ from "lodash";
import { Watch } from "vue-property-decorator";
import { Actions } from "@/shared/core/store/actions";
import { DbProduct } from "../model/DbProduct";
import {
  IsValidPriceRule,
  LengthRule,
  NoCurrencyRule,
  NotEmptyRule,
  ValidationRule
} from "../../core/validation/validation.rules";
import { Getters } from "../../core/store/getters";
import { SnackType } from "../../core/events";
import { productsService } from "../services/products.service";
import { Mutations } from "../../core/store/mutations";

import { ALLERGENS, PRODUCT_NUTRISCORE } from "../../menus/components/menus.constants";
import Base from "../../core/components/Base.vue";
import DbProductDelete from "./DbProductDelete.vue";

@Component({
  components: {
    DbProductDelete
  }
})
export default class DbProductFormDialog extends Base {
  mProduct: Partial<DbProduct> = {};

  valid = false;

  origins: [string, string][] = [];

  productTypesNames: [string, string][] = [];

  @Watch("formDialogDb")
  opening(newValue: boolean, oldValue: boolean): void {
    if (newValue && !oldValue) {
      // Ouverture
      if (this.dbProduct) {
        this.mProduct = _.cloneDeep(this.dbProduct);
      } else {
        this.clear();
      }
    }
  }

  get edition() {
    return Boolean(this.mProduct._id);
  }

  async mounted() {
    const menuConstants = await import(
      `../../../customers/${this.customer}/${this.customer}.constants`
    );
    this.origins = menuConstants.ORIGINS;
    this.productTypesNames = menuConstants.PRODUCT_TYPES_NAMES;
  }

  async submit(closeAfter = false) {
    this.$store.commit(`core/${Mutations.START_LOADING}`, null, { root: true });

    const product: DbProduct = {
      ...(this.mProduct as DbProduct),
      sides: this.mProduct.type === "ACC" ? "" : this.mProduct.sides,
      price: this.mProduct.price || 0,
      nutriscore: this.mProduct.nutriscore || 0
    };
    Promise.resolve()
      .then(async () => {
        if (this.edition && product._id)
          return productsService.update(product._id, product).then(
            () => {
              return "Produit modifié avec succès";
            },
            error => {
              this.snack(
                `Erreur lors de la modification du produit. (code ${error.response?.status})`,
                SnackType.ERROR,
                5000
              );
            }
          );
        return productsService.create(product).then(
          () => {
            return "Produit ajouté avec succès";
          },
          error => {
            this.snack(
              `Erreur lors de l'ajout du produit. (code ${error.response?.status})`,
              SnackType.ERROR,
              5000
            );
          }
        );
      })
      .finally(() => {
        this.$store.dispatch(`dbProduct/${Actions.SET_DB_PRODUCTS}`, null, { root: true });
        if (closeAfter) this.close();
        else this.clear();
      })
      .then(async okMessage => {
        if (okMessage) this.snack(okMessage);
      })
      .finally(() => {
        this.$store.commit(`core/${Mutations.RELEASE_LOADING}`, null, { root: true });
      });
  }

  close() {
    this.formDialogDb = false;
  }

  clear() {
    this.mProduct = {};
    // this.edition = false;
    // if (this.$refs.nameInput) (this.$refs.nameInput as HTMLElement).focus();
    if (this.$refs.form) {
      const form = this.$refs.form as HTMLFormElement;
      form.resetValidation();
    }
  }

  get nameRules(): ValidationRule[] {
    return [NotEmptyRule];
  }

  get priceRules(): ValidationRule[] {
    return [NoCurrencyRule, IsValidPriceRule];
  }

  get typeRules(): ValidationRule[] {
    return [NotEmptyRule];
  }

  get originsRules(): ValidationRule[] {
    return [];
  }

  get sidesRules(): ValidationRule[] {
    return [LengthRule(0, 75)];
  }

  get productTypeItems(): unknown[] {
    return Object.entries(this.productTypesNames).map(([typeCode, typeName]) => {
      return { value: typeCode, text: typeName };
    });
  }

  get allergensItems(): unknown[] {
    return Object.entries(ALLERGENS).map(([code, name]) => {
      return { value: code, text: name };
    });
  }

  get originsItems(): unknown[] {
    return Object.entries(this.origins).map(([code, name]) => {
      return { value: code, text: name };
    });
  }

  get nutriscoreItems(): unknown[] {
    return Object.entries(PRODUCT_NUTRISCORE).map(([code, name]) => {
      return { value: parseInt(code, 10), text: name };
    });
  }

  set formDialogDb(v: boolean) {
    this.$store.commit(`dbProduct/${Mutations.FORM_DIALOG_DB}`, v, { root: true });
  }

  get formDialogDb(): boolean {
    return this.$store.getters[`dbProduct/${Getters.FORM_DIALOG_DB}`];
  }
}
