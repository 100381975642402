
import { SnackType } from "@/shared/core/events";
import {
  FileSizeRule,
  NotEmptyRule,
  ValidationRule
} from "@/shared/core/validation/validation.rules";
import _ from "lodash";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import Base from "../../core/components/Base.vue";
import { Mutations } from "../../core/store/mutations";
import { Image } from "../model/Image";
import { MediaItem } from "../model/MediaItem";
import { mediasService } from "../services/medias.service";
import MediaItemDelete from "./MediaItemDelete.vue";

@Component({
  components: {
    MediaItemDelete
  }
})
export default class MediaItemDialog extends Base {
  @Prop() mediaId!: string;

  @Prop() mediaItem!: MediaItem;

  slots: {
    image: string | Blob | null;
    rank: number;
  }[] = [];

  valid = false;

  menuStart = false;

  menuEnd = false;

  today = new Date(Date.now()).toISOString();

  newItem: MediaItem = {
    start: new Date().toISOString(),
    end: new Date().toISOString(),
    images: [],
    name: ""
  };

  get edition() {
    return Boolean(this.newItem?.id);
  }

  get nameRules(): ValidationRule[] {
    return [NotEmptyRule];
  }

  get startDateFormatted() {
    return this.formatDate(this.newItem.start);
  }

  get endDateFormatted() {
    return this.formatDate(this.newItem.end);
  }

  get notEmptyRule(): ValidationRule[] {
    return [NotEmptyRule];
  }

  get fileRule(): ValidationRule[] {
    return [NotEmptyRule, FileSizeRule];
  }

  clear() {
    if (!this.mediaItem) {
      this.newItem = {
        start: "",
        end: "",
        images: [],
        name: "",
        nameEn: ""
      };
    }
    if (this.$refs.form) {
      const form = this.$refs.form as HTMLFormElement;
      form.resetValidation();
    }
  }

  @Watch("mediaItem")
  opening(): void {
    if (this.mediaItem) {
      this.newItem = _.cloneDeep(this.mediaItem);
      this.prepareSlots();
    } else this.clear();
  }

  mounted() {
    if (this.mediaItem) {
      this.newItem = _.cloneDeep(this.mediaItem);
      this.prepareSlots();
    } else this.clear();
  }

  formatDate(date: string) {
    return new Date(date).toLocaleDateString("fr-fr", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit"
    });
  }

  close() {
    this.$emit("closeDialog");
  }

  endErrors() {
    if (this.newItem.start > this.newItem.end)
      return "La date de début doit être inférieure à la date de fin";
    return "";
  }

  duplicateRankErrors(slot: { image: string | Blob | null; rank: number }) {
    if (this.slots.some(s => s.rank === slot.rank && s.image !== slot.image))
      return "Ce rang est déjà sélectionné.";
    return "";
  }

  allSlotsValid() {
    return !this.slots.every(slot => slot.image && slot.rank);
  }

  addSlot() {
    if (this.slots.length === 0) {
      this.slots.push({ image: null, rank: 1 });
    } else this.slots.push({ image: null, rank: Math.max(...this.slots.map(s => s.rank)) + 1 });
  }

  deleteSlot(slot: { image: string | Blob | null; rank: number }) {
    this.slots = this.slots.filter(s => slot.rank !== s.rank);
  }

  prepareSlots() {
    this.slots = this.newItem.images.map(i => ({ image: i.url, rank: i.rank }));
  }

  isString(image: string | Blob | null) {
    return typeof image === "string";
  }

  async prepareImages(): Promise<Image[]> {
    return Promise.all(
      this.slots.map(async s => {
        if (s.image && typeof s.image !== "string") s.image = await this.uploadImage(s.image);
        return { rank: s.rank, url: s.image as string };
      })
    );
  }

  async submit(closeAfter = false) {
    this.$store.commit(`core/${Mutations.START_LOADING}`, null, { root: true });

    const startDate = new Date(this.newItem.start);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(this.newItem.end);
    endDate.setHours(0, 0, 0, 0);
    if (startDate.toISOString() === endDate.toISOString()) endDate.setHours(23, 59);

    const mediaItem: MediaItem = {
      ...(this.newItem as MediaItem),
      start: startDate.toISOString(),
      end: endDate.toISOString(),
      images: await this.prepareImages()
    };

    if (!this.site) {
      this.snack("Les conditions de soumissions ne sont pas valides", SnackType.ERROR, 5000);
      return;
    }
    Promise.resolve()
      .then(async () => {
        if (this.edition && mediaItem.id)
          return mediasService.updateMediaItem(this.mediaId, mediaItem).then(
            response => {
              return { message: "Document modifié avec succès", id: response._id };
            },
            error => {
              this.snack(
                `Erreur lors de la modification du document. (code ${error.response?.status})`,
                SnackType.ERROR,
                5000
              );
            }
          );
        return mediasService.createMediaItem(this.mediaId, mediaItem).then(
          response => {
            return { message: "Document ajouté avec succès", id: response._id };
          },
          error => {
            this.snack(
              `Erreur lors de l'ajout du document. (code ${error.response?.status})`,
              SnackType.ERROR,
              5000
            );
          }
        );
      })
      .finally(() => {
        this.close();
      })
      .then(async response => {
        if (response) {
          this.snack(response.message);
          this.$store.commit(`media/${Mutations.SELECT_MEDIA_ID}`, response.id, { root: true });
        }
      })
      .finally(() => {
        this.$store.commit(`core/${Mutations.RELEASE_LOADING}`, null, { root: true });
      });
  }

  async uploadImage(image: string | Blob) {
    this.startLoading();
    const imageFormData = new FormData();
    imageFormData.append("image", image);
    const url = await mediasService.uploadImage(imageFormData);
    this.releaseLoading();
    return url;
  }
}
