import { Article } from "../model/Article";
import { SecureService } from "../../core/services/secure.service";

export class ArticlesService extends SecureService {
  constructor() {
    super({
      baseURL: `${process.env.VUE_APP_API_URL}articles/`
    });
  }

  async getArticles(id: string): Promise<Article[]> {
    return (await this.client.get<Article[]>(`admin/${id}`)).data;
  }

  async uploadImageArticle(image: FormData) {
    return (
      await this.client.post<string>("upload", image, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
    ).data;
  }

  async createArticle(article: Article) {
    return (await this.client.post<Article>("", article)).data;
  }

  async updateArticle(id: string, article: Article) {
    return (await this.client.patch<Article>(id, article)).data;
  }

  async deleteArticle(id: string) {
    return (await this.client.delete<Article>(id)).data;
  }
}

export const articlesService = new ArticlesService();
