
import Vue from "vue";
import Component from "vue-class-component";
import { menusService } from "@/shared/menus/services/menus.service";

import { MenuItem } from "../../menus/model/MenuItem";
import Site from "../model/Site";
import { Getters } from "../store/getters";
import { Mutations } from "../store/mutations";

@Component({})
export default class CoreNavigationDrawer extends Vue {
  menusEnabled = false;

  articlesEnabled = false;

  mediasEnabled = false;

  feedbackEnabled = false;

  async mounted() {
    this.menusEnabled = await this.isMenusEnabled();
    this.articlesEnabled = await this.isArticlesEnabled();
    this.mediasEnabled = await this.isMediasEnabled();
    this.feedbackEnabled = await this.isFeedbackEnabled();
  }

  get items(): MenuItem[] {
    return [
      {
        icon: "mdi-view-dashboard",
        title: "Tableau de bord",
        to: "/",
        disabled: false,
        show: true
      },
      {
        icon: "mdi-playlist-edit",
        title: "Saisie des menus",
        to: "/menus",
        disabled: !this.siteSelected,
        show: this.menusEnabled
      },
      {
        icon: "mdi-square-edit-outline",
        title: "Saisie des actualités",
        to: "/articles",
        disabled: !this.siteSelected,
        show: this.articlesEnabled
      },
      {
        icon: "mdi-image-multiple",
        title: "Saisie des médias",
        to: "/medias",
        disabled: !this.siteSelected,
        show: this.mediasEnabled
      }
    ];
  }

  redirectToApp() {
    return {
      icon: "mdi-open-in-new",
      title: "Voir sur l'app",
      href: `${process.env.VUE_APP_APP_CONVIVES_URL}/${this.site?.slug}`,
      target: "_blank",
      disabled: !this.siteSelected
    };
  }

  exportRatings() {
    return {
      icon: "mdi-file-export",
      title: "Export des avis produits",
      disabled: !this.siteSelected
    };
  }

  setDrawer(value: boolean): void {
    this.$store.commit(`core/${Mutations.SET_DRAWER}`, value, { root: true });
  }

  get drawer(): boolean {
    return this.$store.state.core.drawer === true;
  }

  get siteSelected(): boolean {
    return this.$store.getters[`core/${Getters.SELECTED}`];
  }

  get site(): Site | null {
    return this.$store.state.core.site;
  }

  get isAdmin(): boolean {
    return this.$store.getters[`core/${Getters.IS_ADMIN}`];
  }

  get version() {
    return process.env.VUE_APP_VERSION || "1";
  }

  get customer(): string {
    return process.env.VUE_APP_CUSTOMER;
  }

  async isMenusEnabled() {
    return (await import(`../../../customers/${this.customer}/config`)).MENUS_MODULE_ENABLED;
  }

  async isArticlesEnabled() {
    return (await import(`../../../customers/${this.customer}/config`)).ARTICLES_MODULE_ENABLED;
  }

  async isMediasEnabled() {
    return (await import(`../../../customers/${this.customer}/config`)).MEDIAS_MODULE_ENABLED;
  }

  async isFeedbackEnabled() {
    return (await import(`../../../customers/${this.customer}/config`)).FEEDBACK_MODULE_ENABLED;
  }

  exportFeedbackProducts() {
    if (this.site)
      menusService.exportFeedbackProducts(this.site.customerId).then(data => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `Feedback_${this.site?.name}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  }
}
