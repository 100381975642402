import Site from "../model/Site";
import SiteInList from "../model/SiteInList";
import { SecureService } from "./secure.service";

export class SiteService extends SecureService {
  constructor() {
    super({
      baseURL: process.env.VUE_APP_API_URL
    });
  }

  async getSitesList(): Promise<SiteInList[]> {
    return (await this.client.get<SiteInList[]>("sites/list")).data;
  }

  async getSite(customerId: string): Promise<Site> {
    return (await this.client.get<Site>(`sites/${customerId}`)).data;
  }
}

export const siteService = new SiteService();
