import { AuthResponse } from "../model/AuthResponse";
import { User } from "../model/User";
import { SecureService } from "./secure.service";

export class UsersService extends SecureService {
  constructor() {
    super({
      baseURL: process.env.VUE_APP_API_URL
    });
  }

  async getMyUser(): Promise<User> {
    return (await this.client.get<User>("/auth/me")).data;
  }

  async login(form: { email: string; password: string }): Promise<AuthResponse> {
    return (await this.client.post<AuthResponse>("/auth/login", form)).data;
  }

  async logout(refreshToken: string): Promise<boolean> {
    return (await this.client.post<boolean>("/auth/logout", { refreshToken })).data;
  }

  async requestResetPassword(form: { email: string }): Promise<{ email: string }> {
    return (await this.client.post<{ email: string }>("/user/password/reset", form)).data;
  }

  async verifyToken(token: string): Promise<void> {
    return (await this.client.get<void>("/user/password/reset", { params: { token } })).data;
  }

  async resetPassword(form: {
    email: string;
    password: string;
    token: string;
  }): Promise<{ token: string; created: boolean }> {
    return (
      await this.client.put<{ token: string; created: boolean }>("/user/password/reset", form)
    ).data;
  }
}

export const usersService = new UsersService();
