import { Mutations } from "@/shared/core/store/mutations";
import { Actions } from "@/shared/core/store/actions";
import { Getters } from "@/shared/core/store/getters";
import { Article } from "../model/Article";
import { articlesService } from "../services/articles.service";
import { ArticleState } from "../model/ArticleState";

const articleState: ArticleState = {
  articles: []
};

export const article = {
  namespaced: true,
  state: articleState,
  mutations: {
    [Mutations.SET_ARTICLES]: (state: ArticleState, articles: Article[]) => {
      state.articles = articles;
    }
  },
  actions: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    [Actions.SET_ARTICLES]: async ({ commit }: { commit: Function }, customerId: string) => {
      commit(`article/${Mutations.SET_ARTICLES}`, await articlesService.getArticles(customerId), {
        root: true
      });
    }
  },
  getters: {
    [Getters.ARTICLES]: (state: ArticleState): Article[] => state.articles
  }
};
