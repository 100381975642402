import { Actions } from "@/shared/core/store/actions";
import { Getters } from "@/shared/core/store/getters";
import { Mutations } from "@/shared/core/store/mutations";
import { DbProduct } from "../model/DbProduct";
import { DbProductState } from "../model/DbProductState";
import { productsService } from "../services/products.service";

const dbProductState: DbProductState = {
  formDialogDb: false,
  dbProductId: null,
  dbProducts: []
};

export const dbProduct = {
  namespaced: true,
  state: dbProductState,
  mutations: {
    [Mutations.FORM_DIALOG_DB]: (state: DbProductState, v: boolean) => {
      state.formDialogDb = v;
    },
    [Mutations.SELECT_DB_PRODUCT_ID]: (state: DbProductState, dbProductId: string | null) => {
      state.dbProductId = dbProductId;
    },

    [Mutations.SET_DB_PRODUCTS]: (state: DbProductState, dbProducts: DbProduct[]) => {
      state.dbProducts = dbProducts;
    }
  },
  actions: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    [Actions.SET_DB_PRODUCTS]: async ({ commit }: { commit: Function }) => {
      commit(`dbProduct/${Mutations.SET_DB_PRODUCTS}`, await productsService.dbProducts(), {
        root: true
      });
    }
  },
  getters: {
    [Getters.FORM_DIALOG_DB]: (state: DbProductState) => state.formDialogDb,

    [Getters.DB_PRODUCTS]: (state: DbProductState): DbProduct[] => state.dbProducts,
    [Getters.DB_PRODUCT]: (state: DbProductState) =>
      state.dbProducts.find(product => product._id === state.dbProductId)
  }
};
