
import Component from "vue-class-component";
import { Getters } from "../store/getters";
import { Mutations } from "../store/mutations";
import { usersService } from "../services/users.service";
import SiteSelector from "./SiteSelector.vue";
import Base from "./Base.vue";

@Component({
  components: { SiteSelector }
})
export default class CoreAppBar extends Base {
  hasLogoApp = false;

  async mounted() {
    this.hasLogoApp = await this.customerHasLogoApp();
  }

  get email(): string {
    return this.$store.state.core.user?.email;
  }

  switchDrawer(): void {
    this.$store.commit(`core/${Mutations.SET_DRAWER}`, !this.$store.state.core.drawer, {
      root: true
    });
  }

  get isSuperadmin(): boolean {
    return this.$store.getters[`core/${Getters.IS_SUPERADMIN}`];
  }

  get isOauth(): boolean {
    return this.$store.getters[`core/${Getters.IS_OAUTH}`];
  }

  logout() {
    if (localStorage.apikey) {
      delete localStorage.apikey;
      this.$router.push({ name: "Login" });
    }
    if (localStorage.refreshToken)
      usersService.logout(localStorage.refreshToken).then(() => {
        delete localStorage.accessToken;
        delete localStorage.refreshToken;
        this.$router.push({ name: "Login" });
      });
  }
}
