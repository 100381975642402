import { Actions } from "@/shared/core/store/actions";
import { Getters } from "@/shared/core/store/getters";
import { Mutations } from "@/shared/core/store/mutations";
import { Media } from "../model/Media";
import { MediaState } from "../model/MediaState";
import { mediasService } from "../services/medias.service";

const mediaState: MediaState = {
  medias: [],
  formDialogMedia: false,
  mediaId: null
};

export const media = {
  namespaced: true,
  state: mediaState,
  mutations: {
    [Mutations.SET_MEDIAS]: (state: MediaState, medias: Media[]) => {
      state.medias = medias;
    },
    [Mutations.FORM_DIALOG_MEDIA]: (state: MediaState, formDialog: boolean) => {
      state.formDialogMedia = formDialog;
    },
    [Mutations.SELECT_MEDIA_ID]: (state: MediaState, mediaId: string) => {
      state.mediaId = mediaId;
    }
  },
  actions: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    [Actions.SET_MEDIAS]: async ({ commit }: { commit: Function }, customerId: string) => {
      commit(`media/${Mutations.SET_MEDIAS}`, await mediasService.getMedias(customerId), {
        root: true
      });
    }
  },
  getters: {
    [Getters.MEDIAS]: (state: MediaState): Media[] => state.medias,
    [Getters.FORM_DIALOG_MEDIA]: (state: MediaState): boolean => state.formDialogMedia,
    [Getters.MEDIA]: (state: MediaState) => state.medias.find(m => m._id === state.mediaId)
  }
};
