export const DATE_CONFIG: Intl.DateTimeFormatOptions = {
  weekday: "long",
  day: "numeric",
  month: "long",
  year: "numeric"
};

export const PRODUCT_NUTRISCORE = {
  1: "A",
  2: "B",
  3: "C",
  4: "D",
  5: "E"
};

export const ALLERGENS = {
  GLU: "Gluten",
  CRU: "Crustacés",
  OEU: "Oeuf",
  POI: "Poisson",
  ARA: "Arachides",
  SOJ: "Soja",
  LAI: "Lait",
  FCQ: "Fruits à coque",
  CEL: "Céleri",
  MOU: "Moutarde",
  SES: "Sésame",
  SUL: "Sulfites",
  LUP: "Lupin",
  MOL: "Mollusques"
};
