/* eslint-disable @typescript-eslint/ban-types */
import { Menu } from "@/shared/menus/model/Menu";
import { Product } from "@/shared/menus/model/Product";
import { Service } from "@/shared/menus/model/Service";
import { Zone } from "@/shared/menus/model/Zone";
import _ from "lodash";

import { Actions } from "@/shared/core/store/actions";
import { coreState } from "@/shared/core/store/coreState";
import { Getters } from "@/shared/core/store/getters";
import { Mutations } from "@/shared/core/store/mutations";
import { MenuState } from "../model/MenuState";
import { menusService } from "../services/menus.service";

const menuState: MenuState = {
  formDialog: false,
  formDialogMove: false,
  formDialogDuplicate: false,
  date: null,
  productId: null,
  service: null,
  menus: [],
  currentMenu: null,
  zone: null
};

export const menu = {
  namespaced: true,
  state: menuState,
  mutations: {
    [Mutations.FORM_DIALOG]: (state: MenuState, v: boolean) => {
      state.formDialog = v;
    },
    [Mutations.FORM_DIALOG_MOVE]: (state: MenuState, v: boolean) => {
      state.formDialogMove = v;
    },
    [Mutations.FORM_DIALOG_DUPLICATE]: (state: MenuState, v: boolean) => {
      state.formDialogDuplicate = v;
    },
    [Mutations.SELECT_DATE]: (state: MenuState, newDate: Date | null) => {
      state.date = newDate;
    },
    [Mutations.SELECT_PRODUCT_ID]: (state: MenuState, productId: string | null) => {
      state.productId = productId;
    },

    [Mutations.SELECT_SERVICE]: (state: MenuState, service: Service | null) => {
      state.service = service;
    },

    [Mutations.SET_MENUS]: (state: MenuState, menus: Menu[]) => {
      state.menus = menus;
    },
    [Mutations.SET_CURRENT_MENU]: (state: MenuState, currentMenu: Menu) => {
      state.currentMenu = currentMenu;
      const { site } = coreState;
      if ((!state.date || !state.service) && site) {
        state.date = new Date(currentMenu.date);
        state.service =
          site?.services.filter(service => service.code === currentMenu.serviceCode)[0] || null;
      }
    },
    [Mutations.SET_ZONE]: (state: MenuState, zone: Zone) => {
      state.zone = zone;
    }
  },
  actions: {
    [Actions.SET_MENUS]: async ({ commit }: { commit: Function }, customerId: string) => {
      const menus = await menusService.getMenus(customerId);
      commit(`menu/${Mutations.SET_MENUS}`, menus || [], { root: true });
    },
    [Actions.SET_CURRENT_MENU]: async ({ commit }: { commit: Function }, menuId: string) => {
      const currentMenu = await menusService.getCurrentMenu(menuId);
      commit(`menu/${Mutations.SET_CURRENT_MENU}`, currentMenu, { root: true });
    }
  },
  getters: {
    [Getters.FORM_DIALOG]: (state: MenuState) => state.formDialog,
    [Getters.FORM_DIALOG_MOVE]: (state: MenuState) => state.formDialogMove,
    [Getters.FORM_DIALOG_DUPLICATE]: (state: MenuState) => state.formDialogDuplicate,
    [Getters.DATE]: (state: MenuState) => state.date,
    [Getters.SERVICE]: (state: MenuState) => state.service,
    [Getters.MENUS_DATES]: (state: MenuState) => {
      if (state.menus) {
        return _.uniq(state.menus.map(m => m.date))
          .sort()
          .map(dateStr => new Date(dateStr));
      }
      return [];
    },
    [Getters.SERVICES]: (): Service[] => {
      const { site } = coreState;
      if (site) return site.services;
      return [];
    },
    [Getters.ZONE]: (state: MenuState): Zone | null => state.zone,
    [Getters.ZONES]: (): Zone[] => {
      const { site } = coreState;
      if (site) return site.zones;
      return [];
    },
    [Getters.CURRENT_MENU]: (state: MenuState) => state.currentMenu,
    [Getters.MENUS]: (state: MenuState): Menu[] => state.menus,
    [Getters.PRODUCTS]: (state: MenuState) => (zoneCode: string) =>
      state.currentMenu?.products.filter(
        (product: Product) =>
          // product.type !== PRODUCT_TYPES.FORMULA &&
          product.zoneCode === zoneCode &&
          !coreState.site?.zones
            .filter((zone: Zone) => zone.formula)
            .some((zone: Zone) => zone.code === product.zoneCode)
      ) || [],
    [Getters.FORMULA_ZONES]: (): Zone[] =>
      coreState.site?.zones.filter((zone: Zone) => zone.formula) || [],
    [Getters.FORMULA_PRODUCTS]: (state: MenuState) => (zoneCode: string) =>
      state.currentMenu?.products.filter((product: Product) => product.zoneCode === zoneCode) || [],
    [Getters.PRODUCT]: (state: MenuState) => {
      if (state.currentMenu)
        return state.currentMenu.products.find(product => product._id === state.productId);
      return undefined;
    }
  }
};
