
import Base from "@/shared/core/components/Base.vue";
import { SnackType } from "@/shared/core/events";
import { Getters } from "@/shared/core/store/getters";
import { Mutations } from "@/shared/core/store/mutations";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import clip from "text-clipper";

import { Booking } from "../model/Booking";
import { BookingConfiguration } from "../model/BookingConfiguration";
import { Slot } from "../model/Slot";
import { bookingsService } from "../services/bookings.service";

@Component
export default class Bookings extends Base {
  get bookings(): Booking[] {
    return this.$store.getters[`booking/${Getters.BOOKINGS}`];
  }

  get bookingConfiguration(): BookingConfiguration {
    return this.$store.getters[`booking/${Getters.BOOKING_CONFIGURATION}`];
  }

  headers: DataTableHeader[] = [
    {
      text: "Nom Prénom",
      align: "start",
      value: "lastName"
    },
    { text: "Email", value: "email", sortable: false },
    { text: "Téléphone", value: "mobile", sortable: false },
    { text: "Date", value: "date", sortable: true },
    { text: "Créneau", value: "slot", sortable: false },
    { text: "Nbr. de place", value: "seats" },
    { text: "État", value: "valid" },
    { text: "Commentaire", value: "comment", sortable: false },
    { text: "Statut de présence", value: "completed" },
    { text: "Actions", value: "actions", sortable: false }
  ];

  status = [
    { text: "Validée", value: true },
    { text: "En attente de validation", value: false }
  ];

  statusComplete = [
    { text: "Présent", value: true },
    { text: "Absent", value: false }
  ];

  menu = false;

  dialogDelete = false;

  search = "";

  selectedSlots: string[] = [];

  selectedRange: string[] = [];

  selectedStatus: boolean | null = null;

  selectedBooking: Booking | null = null;

  selectedCompleted: boolean | null = null;

  filteredBookings: Booking[] = [];

  async mounted() {
    this.filter();
  }

  @Watch("$store.state.booking.bookingConfiguration")
  filter() {
    let { bookings } = this;
    if (this.selectedRange.length === 2) {
      const startDate = new Date(this.selectedRange[0]).getTime();
      const endDate = new Date(this.selectedRange[1]).setHours(23, 59, 0);
      bookings = bookings.filter(
        booking =>
          new Date(booking.date).getTime() >= startDate &&
          new Date(booking.date).getTime() <= endDate
      );
    }
    if (this.selectedSlots.length > 0) {
      bookings = bookings.filter(booking =>
        this.selectedSlots.some(slotStartTime => {
          const slotStart = this.getBookingSlot(booking);
          if (typeof slotStart === "string") {
            return slotStartTime === slotStart;
          }
          return slotStartTime === slotStart.startTime;
        })
      );
    }
    if (this.selectedStatus !== null) {
      bookings = bookings.filter(booking => booking.valid === this.selectedStatus);
    }

    if (this.selectedCompleted !== null) {
      bookings = bookings.filter(booking => booking.completed === this.selectedCompleted);
    }

    if (this.search && this.search.length > 0) {
      bookings = bookings.filter(
        booking =>
          booking.lastName.includes(this.search) ||
          booking.firstName.includes(this.search) ||
          booking.email.includes(this.search) ||
          (booking.mobile && booking.mobile.includes(this.search))
      );
    }
    this.filteredBookings = bookings;
  }

  getBookingSlot(booking: Booking): Slot | string {
    const date = new Date(
      new Date(booking.date).toLocaleString("en", { timeZone: "Europe/Paris" })
    );
    if (date && booking && booking.date) {
      const startTime = `${date.getHours()}:${
        date.getMinutes() < 10
          ? date
              .getMinutes()
              .toString(10)
              .padStart(2, "0")
          : date.getMinutes()
      }`;

      return (
        this.bookingConfiguration?.slots.find(slot => slot.startTime === startTime) ||
        `${startTime} / Créneau invalide`
      );
    }
    return "Date invalide";
  }

  @Watch("selectedRange")
  formatInputDate() {
    if (this.selectedRange.length === 2) {
      const [startYear, startMonth, startDay] = this.selectedRange[0].split("-");
      const startDate = `${startDay.padStart(2, "0")}/${startMonth.padStart(2, "0")}/${startYear}`;
      const [year, month, day] = this.selectedRange[1].split("-");
      const endDate = `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
      return `${startDate} au ${endDate}`;
    }
    return null;
  }

  formatBookingDate(bookingDate: string) {
    const date = new Date(bookingDate);
    if (date)
      return date.toLocaleString("fr-FR", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        timeZone: "Europe/Paris"
      });
    return "";
  }

  formatSlot(slot: Slot | string): string {
    if (typeof slot === "string") {
      return slot;
    }
    return `${slot.startTime} - ${slot.endTime}`;
  }

  capitalizeFirstName(value: string) {
    let str = value.trim().toLocaleLowerCase();
    if (str?.length) {
      if (str.search(/\s|-/g) < 0) {
        return this.transform(str);
      }
      if (str.search("-") >= 0) {
        str = this.transform(str, "-");
      }
      if (str.search(" ") >= 0) {
        str = this.transform(str, " ");
      }
    }
    return str;
  }

  transform(value: string, separator?: string) {
    if (!separator) {
      return value[0].toLocaleUpperCase() + value.substr(1);
    }
    const resArr: string[] = [];
    const strArr = value.split(separator);
    strArr.forEach(element => {
      resArr.push(element[0]?.toLocaleUpperCase() + element.substr(1));
    });
    return resArr.join(separator);
  }

  formatNameContact(booking: Booking) {
    return `${this.capitalizeFirstName(booking.firstName)} ${booking.lastName.toUpperCase()}`;
  }

  formatTextContact(text: string) {
    return clip(text, 10);
  }

  async validateItemBooking(item: Booking) {
    this.selectedBooking = { ...item };
    this.startLoading();
    if (this.selectedBooking._id && this.site) {
      await bookingsService.validateBooking(this.selectedBooking._id).then(
        async () => {
          this.snack(`Réservation validée avec succès`);
          const updatedBookings = await bookingsService
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .getBookings(this.site!.customerId)
            .catch(e => {
              this.snack(
                `Erreur lors de la mise à jour des réservations. (${e.response?.status}) Actualisez la page.`,
                SnackType.ERROR,
                5000
              );
            });
          this.$store.commit(`booking/${Mutations.SET_BOOKINGS}`, updatedBookings, { root: true });
        },
        e => {
          this.snack(
            e.response?.data?.message ||
              `Impossible de valider cette réservation (${e.response?.status})`,
            SnackType.ERROR
          );
        }
      );
    }
    this.releaseLoading();
  }

  cancelItemBooking(item: Booking) {
    this.selectedBooking = { ...item };
    this.dialogDelete = true;
  }

  async completeItemBooking(newStatus: boolean, item: Booking) {
    this.selectedBooking = { ...item };
    this.startLoading();
    if (this.selectedBooking._id && this.site) {
      await bookingsService.completeBooking(this.selectedBooking._id, newStatus).then(
        async () => {
          this.snack(`Le statut de présence de cette réservation est mis à jour avec succès`);
          const updatedBookings = await bookingsService
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .getBookings(this.site!.customerId)
            .catch(e => {
              this.snack(
                `Erreur lors de la mise à jour des réservations. (${e.response?.status}) Actualisez la page.`,
                SnackType.ERROR,
                5000
              );
            });
          this.$store.commit(`booking/${Mutations.SET_BOOKINGS}`, updatedBookings, {
            root: true
          });
        },
        e => {
          this.snack(
            e.response?.data?.message ||
              `Impossible de modifier le statut de présence cette réservation (${e.response?.status})`,
            SnackType.ERROR
          );
        }
      );
    }
    this.releaseLoading();
  }

  async cancelConfirmBooking() {
    this.startLoading();
    if (this.selectedBooking && this.selectedBooking._id && this.site) {
      await bookingsService.cancelBooking(this.selectedBooking._id).then(
        async () => {
          this.snack(`Réservation supprimée avec succès`);
          const updatedBookings = await bookingsService
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .getBookings(this.site!.customerId)
            .catch(e => {
              this.snack(
                `Erreur lors de la mise à jour des réservations. (${e.response?.status}) Actualisez la page.`,
                SnackType.ERROR,
                5000
              );
            });
          this.$store.commit(`booking/${Mutations.SET_BOOKINGS}`, updatedBookings, { root: true });
        },
        e => {
          this.snack(
            e.response?.data?.message ||
              `Impossible de supprimer cette réservation (${e.response?.status})`,
            SnackType.ERROR
          );
        }
      );
    }
    this.releaseLoading();
    this.closeDeleteDialog();
  }

  closeDeleteDialog() {
    this.selectedBooking = null;
    this.dialogDelete = false;
  }
}
