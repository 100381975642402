export const Actions = {
  SET_LIST: "a-set-list",
  SELECT_SITE: "a-select-site",
  SET_MENUS: "a-set-menus",
  SET_CURRENT_MENU: "a-set-current-menu",
  SET_ARTICLES: "a-set-articles",
  SET_BOOKINGS: "a-set-bookings",
  SET_BOOKING_CONFIGURATION: "a-set-booking-configuration",
  SET_DB_PRODUCTS: "a-set-dbProducts",
  SET_MEDIAS: "a-set-medias"
};
