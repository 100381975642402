export enum SnackType {
  SUCCESS,
  WARNING,
  ERROR
}

export class SnackEvent {
  color: string;

  constructor(
    public message: string,
    public type: SnackType,
    public timeout = 3000,
    public multiline = false
  ) {
    switch (this.type) {
      case SnackType.ERROR:
        this.color = "red darken-1 white--text";
        break;
      case SnackType.WARNING:
        this.color = "orange darken-1 white--text";
        break;
      default:
        this.color = "green darken-2 white--text";
    }
  }
}

export const SNACK_EVENT_NAME = "snack";
