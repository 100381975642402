import { SecureService } from "../../core/services/secure.service";
import { DbProduct } from "../model/DbProduct";
import { Product } from "../../menus/model/Product";
import { SearchProduct } from "../../menus/model/SearchProduct";
import { Usage } from "../model/Usage";

export class ProductsService extends SecureService {
  constructor() {
    super({
      baseURL: `${process.env.VUE_APP_API_URL}products`
    });
  }

  async dbProducts() {
    return (await this.client.get<DbProduct[]>(`/`)).data;
  }

  async search(query: string) {
    return (await this.client.get<SearchProduct[]>(`/search/${query}`, {})).data;
  }

  async usage(customerId: string, productId: string) {
    const url = `/usages/${customerId}/${productId}`;
    return (await this.client.post<Usage>(url, {})).data;
  }

  async create(dbProduct: DbProduct) {
    return this.client.post<DbProduct>("/", dbProduct);
  }

  async update(id: string, dbProduct: Partial<DbProduct>) {
    return this.client.patch<DbProduct>(`/${id}`, dbProduct);
  }

  async delete(id: string) {
    return this.client.delete<DbProduct>(`/${id}`);
  }

  mergeSearchProduct(sourceProduct: Partial<Product>, searchProduct: SearchProduct) {
    const newProduct: Product = {
      _id: sourceProduct._id,
      name: searchProduct.name,
      dbSourceId: searchProduct._id,
      type: searchProduct.type,
      zoneCode: sourceProduct.zoneCode || "",
      allergens: searchProduct.allergens || [],
      origins: searchProduct.origins || [],
      nutriscore: searchProduct.nutriscore || undefined,
      price: searchProduct.price || undefined,
      sides: searchProduct.sides || undefined
    };
    return newProduct;
  }
}

export const productsService = new ProductsService();
