
import Component from "vue-class-component";
import ArticleForm from "../components/ArticleForm.vue";
import Base from "../../core/components/Base.vue";
import ArticleCard from "../components/ArticleCard.vue";

@Component({
  components: { ArticleCard, ArticleForm }
})
export default class Articles extends Base {
  search = "";

  dialog = false;

  openCard() {
    this.dialog = true;
  }
}
