import { SecureService } from "../../core/services/secure.service";
import { Booking } from "../model/Booking";
import { BookingConfiguration } from "../model/BookingConfiguration";

export class BookingsService extends SecureService {
  constructor() {
    super({
      baseURL: process.env.VUE_APP_API_URL
    });
  }

  async createBookingConfiguration(bookingConfiguration: BookingConfiguration) {
    return (
      await this.client.post<BookingConfiguration>(`/booking/configuration`, bookingConfiguration)
    ).data;
  }

  async updateBookingConfiguration(bookingConfiguration: Partial<BookingConfiguration>) {
    return (
      await this.client.patch<BookingConfiguration>(`/booking/configuration`, bookingConfiguration)
    ).data;
  }

  async deleteBookingConfiguration(customerId: string) {
    return (await this.client.delete<BookingConfiguration>(`/booking/configuration/${customerId}`))
      .data;
  }

  async getBookingConfiguration(customerId: string) {
    return (await this.client.get<BookingConfiguration>(`/booking/configuration/${customerId}`))
      .data;
  }

  async getBookings(customerId: string): Promise<Booking[]> {
    return (await this.client.get<Booking[]>(`/booking/list/${customerId}`)).data;
  }

  async validateBooking(bookingId: string) {
    return (await this.client.patch<Booking>(`/booking/validate/${bookingId}`)).data;
  }

  async completeBooking(bookingId: string, completed: boolean) {
    return (await this.client.patch<Booking>(`/booking/complete/${bookingId}`, { completed })).data;
  }

  async cancelBooking(bookingId: string) {
    return (await this.client.delete<Booking>(`/booking/${bookingId}`)).data;
  }
}

export const bookingsService = new BookingsService();
