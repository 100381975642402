
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { Product } from "../model/Product";
import { SearchProduct } from "../model/SearchProduct";
import { productsService } from "../../db-products/services/products.service";
import AddProductToDb from "../../db-products/components/AddProductToDb.vue";
import Base from "../../core/components/Base.vue";

const SEARCH_TIMEOUT = 300;

@Component({
  components: {
    AddProductToDb
  }
})
export default class ProductSearchField extends Base {
  @Prop({ required: false, default: false })
  filled!: boolean;

  @Prop({ required: false, default: false })
  autofocus!: boolean;

  @Prop({ required: false })
  rules!: unknown[];

  @Prop({ required: false })
  label!: string;

  @Prop({ required: true })
  mProduct!: Partial<Product>;

  search = "";

  items: SearchProduct[] = [];

  searchTimeout = 0;

  mLoading = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  productTypesNames: any = {};

  async mounted() {
    this.productTypesNames = (
      await import(`../../../customers/${this.customer}/${this.customer}.constants`)
    ).PRODUCT_TYPES_NAMES;
  }

  async request(value: string) {
    if (!value) return;
    this.mLoading = true;
    productsService
      .search(value)
      .then(searchProducts => {
        this.items = searchProducts;
      })
      .finally(() => {
        this.mLoading = false;
      });
  }

  keyUp(event: KeyboardEvent) {
    if (event.target instanceof HTMLInputElement) {
      const { value } = event.target;
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.request(value);
      }, SEARCH_TIMEOUT);
    }
  }

  get value(): string {
    return this.mProduct.name || "";
  }

  onFocus() {
    this.items = [];
  }

  beforeDestroy() {
    clearTimeout(this.searchTimeout);
  }

  typeName(typeCode: string) {
    return this.productTypesNames[typeCode] || "inconnu";
  }

  hasPrice(sProduct: SearchProduct) {
    return !!sProduct.price;
  }

  hasOrigins(sProduct: SearchProduct) {
    return sProduct.origins.length > 0;
  }

  hasAllergens(sProduct: SearchProduct) {
    return sProduct.allergens.length > 0;
  }

  hasNutriscore(sProduct: SearchProduct) {
    return sProduct.nutriscore;
  }
}
