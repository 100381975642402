import Vue from "vue";
import App from "./shared/App.vue";
import vuetify from "./shared/core/plugins/vuetify";
import router from "./shared/core/router";
import { store } from "./shared/core/store";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
