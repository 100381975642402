
import { Actions } from "@/shared/core/store/actions";
import Component from "vue-class-component";
import { SnackType } from "../../core/events";
import { Mutations } from "../../core/store/mutations";
import { mediasService } from "../services/medias.service";

import Base from "../../core/components/Base.vue";

@Component
export default class MediaDelete extends Base {
  deleteConfirmation = false;

  async deleteMedia() {
    if (!this.media) return;
    if (!this.site) return;
    const { customerId } = this.site;
    const mediaId = this.media?._id;

    this.startLoading();
    await mediasService
      .deleteMedia(mediaId)
      .then(() => {
        this.snack("Media supprimé avec succès");
        this.$store.dispatch(`media/${Actions.SET_MEDIAS}`, customerId, { root: true });
      })
      .catch(error => {
        this.snack(
          `Une erreur est survenue pendant la suppression. (${error.response?.status})`,
          SnackType.ERROR,
          8000
        );
      })
      .finally(() => {
        this.$store.commit(`media/${Mutations.FORM_DIALOG_MEDIA}`, false, { root: true });
        this.releaseLoading();
        this.deleteConfirmation = false;
      });
  }
}
