
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import SiteInList from "@/shared/core/model/SiteInList";

import { Getters } from "../../core/store/getters";
import { Mutations } from "../../core/store/mutations";
import { ArrayLengthRule, ValidationRule } from "../../core/validation/validation.rules";
import { Service } from "../model/Service";

import Base from "../../core/components/Base.vue";
import { SnackType } from "../../core/events";
import DateSelectorField from "./DateSelectorField.vue";
import { DATE_CONFIG } from "./menus.constants";
import ProductDelete from "./ProductDelete.vue";
import ProductSearchField from "./ProductSearchField.vue";
import { menusService } from "../services/menus.service";

@Component({
  components: {
    DateSelectorField,
    ProductSearchField,
    ProductDelete
  }
})
export default class MenuFormDialogDuplicate extends Base {
  menuRange = false;

  menuDest = false;

  valid = false;

  selectedService: Service | undefined = undefined;

  selectedRange: string[] = [];

  productTypesNames: [string, string][] = [];

  selectedProductTypes: string[] = [];

  selectedDate = "";

  selectedCustomers: SiteInList[] = [];

  today = new Date();

  minDate = new Date();

  @Watch("formDialog")
  opening(newValue: boolean, oldValue: boolean): void {
    if (newValue && !oldValue) {
      // Ouverture
      this.selectedService = this.$store.getters[`menu/${Getters.SERVICE}`];
      this.selectedCustomers = this.list.filter(
        customer => customer.customerId === this.site?.customerId
      );
    }
  }

  async mounted() {
    const menuConstants = await import(
      `../../../customers/${this.customer}/${this.customer}.constants`
    );
    this.productTypesNames = menuConstants.PRODUCT_TYPES_NAMES;
    this.today.setUTCHours(0, 0, 0, 0);
    this.minDate.setUTCHours(0, 0, 0, 0);
    this.minDate.setUTCDate(this.minDate.getDate() - 90);
  }

  async submit() {
    if (
      !this.site ||
      !this.selectedRange.length ||
      !this.selectedCustomers.length ||
      !this.selectedService ||
      !this.selectedDate
    ) {
      this.snack("Les conditions de soumissions ne sont pas valides", SnackType.ERROR, 5000);
      return;
    }
    const {
      site,
      selectedRange,
      selectedService,
      selectedProductTypes,
      selectedCustomers,
      selectedDate
    } = this;

    Promise.resolve().then(() => {
      return menusService
        .duplicateMenus(
          new Date(selectedRange[0]).toISOString(),
          new Date(selectedRange[1]).toISOString(),
          site.customerId,
          selectedService.code,
          new Date(selectedDate).toISOString(),
          selectedCustomers.map(customer => customer.customerId),
          selectedProductTypes
        )
        .then(() => {
          return "Menus copiés avec succès";
        })
        .catch(error => {
          this.snack(
            `Erreur lors de la duplication des menus (code ${error.response?.status})`,
            SnackType.ERROR,
            5000
          );
          throw error;
        })
        .finally(() => {
          this.close();
        })
        .then(async okMessage => {
          this.$store.commit(
            `menu/${Mutations.SET_MENUS}`,
            await menusService.getMenus(site.customerId),
            { root: true }
          );
          if (okMessage) this.snack(okMessage);
        })
        .finally(() => {
          this.$store.commit(`core/${Mutations.RELEASE_LOADING}`, { root: true });
        });
    });
  }

  close() {
    this.selectedCustomers = [];
    this.selectedRange = [];
    this.selectedProductTypes = [];
    this.selectedDate = "";
    if (this.$refs.form) {
      const form = this.$refs.form as HTMLFormElement;
      form.resetValidation();
    }
    this.formDialog = false;
  }

  set formDialog(v: boolean) {
    this.$store.commit(`menu/${Mutations.FORM_DIALOG_DUPLICATE}`, v, { root: true });
  }

  get formDialog(): boolean {
    return this.$store.getters[`menu/${Getters.FORM_DIALOG_DUPLICATE}`];
  }

  get productTypeItems(): unknown[] {
    return Object.entries(this.productTypesNames).map(([typeCode, typeName]) => {
      return { value: typeCode, text: typeName };
    });
  }

  set productTypes(v: string[]) {
    this.selectedProductTypes = v;
  }

  get productTypes(): string[] {
    return this.selectedProductTypes;
  }

  set dateRange(v: string[]) {
    if (v.length > 1) this.menuRange = false;
    this.selectedRange = v;
  }

  get dateRange(): string[] {
    return this.selectedRange;
  }

  get formattedRange(): string {
    if (this.selectedRange.length > 1)
      return `${new Date(this.selectedRange[0]).toLocaleString("fr-FR", DATE_CONFIG)} - ${new Date(
        this.selectedRange[1]
      ).toLocaleString("fr-FR", DATE_CONFIG)}`;
    return "";
  }

  get formattedValue(): string {
    if (this.selectedDate) return new Date(this.selectedDate).toLocaleString("fr-FR", DATE_CONFIG);
    return "";
  }

  get valueForDatepicker(): string {
    if (this.selectedDate) return new Date(this.selectedDate).toISOString().substring(0, 10);
    return "";
  }

  set dateDest(v: string) {
    if (v) this.menuDest = false;
    this.selectedDate = v;
  }

  get dateDest(): string {
    return this.selectedDate;
  }

  set customerIds(sites: SiteInList[]) {
    this.selectedCustomers = sites;
  }

  get customerIds(): SiteInList[] {
    return this.selectedCustomers;
  }

  set isAllSites(event: boolean) {
    this.selectedCustomers = event ? this.list : [];
  }

  get isAllSites() {
    return this.selectedCustomers.length === this.list.length;
  }

  get customersRule(): ValidationRule[] {
    return [ArrayLengthRule];
  }

  allowedDateRange(val: string) {
    const date = new Date(val);
    return date.getTime() > this.minDate.getTime() && !(date.getDay() === 0 || date.getDay() === 6);
  }

  allowedDateDest(val: string) {
    const date = new Date(val);
    date.setUTCHours(0, 0, 0, 0);
    return date.getTime() >= this.today.getTime() && !(date.getDay() === 0 || date.getDay() === 6);
  }
}
