import { SecureService } from "../../core/services/secure.service";
import { Media } from "../model/Media";
import { MediaItem } from "../model/MediaItem";

export class MediasService extends SecureService {
  constructor() {
    super({
      baseURL: `${process.env.VUE_APP_API_URL}medias/`
    });
  }

  async getMedias(id: string): Promise<Media[]> {
    return (await this.client.get<Media[]>(`admin/${id}`)).data;
  }

  async uploadImageMediaItem(image: FormData) {
    return (
      await this.client.post<string>("upload", image, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
    ).data;
  }

  async createMedia(media: Omit<Media, "id" | "updatedAt">) {
    return (await this.client.post<Media>("", media)).data;
  }

  async updateMedia(id: string, media: Media) {
    return (await this.client.patch<Media>(id, media)).data;
  }

  async deleteMedia(id: string) {
    return (await this.client.delete<Media>(id)).data;
  }

  async createMediaItem(id: string, media: MediaItem) {
    return (await this.client.post<Media>(`${id}/items`, media)).data;
  }

  async updateMediaItem(id: string, media: MediaItem) {
    return (await this.client.patch<Media>(`${id}/items`, media)).data;
  }

  async deleteMediaItem(id: string, idItem: string) {
    return (await this.client.delete<Media>(`${id}/items/${idItem}`)).data;
  }

  async uploadImage(image: FormData) {
    return (
      await this.client.post<string>("upload", image, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
    ).data;
  }
}

export const mediasService = new MediasService();
