
import Component from "vue-class-component";
import { Actions } from "@/shared/core/store/actions";
import { Mutations } from "@/shared/core/store/mutations";
import { Getters } from "@/shared/core/store/getters";
import { ALLERGENS, PRODUCT_NUTRISCORE } from "../../menus/components/menus.constants";
import { DbProduct } from "../model/DbProduct";
import DbProductFormDialog from "../components/DbProductFormDialog.vue";
import Base from "../../core/components/Base.vue";

@Component({
  components: {
    DbProductFormDialog
  }
})
export default class DbProductsTable extends Base {
  search = "";

  types: [string, string][] = [];

  allergens = Object.entries(ALLERGENS);

  origins: [string, string][] = [];

  nutriscores = Object.entries(PRODUCT_NUTRISCORE);

  selectedTypes: string[] = [];

  headers = [
    { text: "Type", sortable: true, value: "type" },
    { text: "Nom", sortable: true, value: "name" },
    { text: "Nutriscore", sortable: true, value: "nutriscore" },
    { text: "Prix", sortable: true, value: "price" },
    { text: "Allergènes", sortable: true, value: "allergens" },
    { text: "Certifications", sortable: true, value: "origins" },
    { text: "Accompagnement", sortable: false, value: "sides" },
    { text: "Actions", sortable: false, value: "actions" }
  ];

  get dbProducts(): DbProduct[] {
    return this.$store.getters[`dbProduct/${Getters.DB_PRODUCTS}`];
  }

  async mounted() {
    this.$store.dispatch(`dbProduct/${Actions.SET_DB_PRODUCTS}`, null, { root: true });
    const menuConstants = await import(
      `../../../customers/${this.customer}/${this.customer}.constants`
    );
    this.origins = Object.entries(menuConstants.ORIGINS);
    this.types = Object.entries(menuConstants.PRODUCT_TYPES_NAMES);
  }

  getTypeName(enumType: string): string {
    return this.types.filter(item => item[0] === enumType)[0]
      ? this.types.filter(item => item[0] === enumType)[0][1]
      : "";
  }

  getNutriscore(number: string): string {
    if (number) return this.nutriscores.filter(item => item[0] === number.toString())[0][1];
    return "";
  }

  getAllergen(enumAllergen: string): string {
    const allergen = this.allergens.filter(item => item[0] === enumAllergen)[0];
    return allergen ? allergen[1] : "";
  }

  getOrigin(enumOrigin: string): string {
    const origin = this.origins.filter(item => item[0] === enumOrigin)[0];
    return origin ? origin[1] : "";
  }

  filteredProducts() {
    let { dbProducts } = this;
    if (this.search && this.search.length > 0) {
      dbProducts = dbProducts.filter(product =>
        product.name.toLowerCase().includes(this.search.toLowerCase())
      );
    }
    if (this.selectedTypes.length > 0)
      dbProducts = dbProducts.filter(product =>
        this.selectedTypes.some(selectedType => selectedType === product.type)
      );
    return dbProducts;
  }

  createProduct() {
    this.$store.commit(`dbProduct/${Mutations.FORM_DIALOG_DB}`, true, { root: true });
  }

  editProduct(product: DbProduct) {
    this.$store.commit(`dbProduct/${Mutations.SELECT_DB_PRODUCT_ID}`, product._id, { root: true });
    this.$store.commit(`dbProduct/${Mutations.FORM_DIALOG_DB}`, true, { root: true });
  }
}
