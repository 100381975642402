import { WorkingDay } from "../model/WorkingDay";

export const DefaultWorkingDays: WorkingDay[] = [
  { weekDay: 0, enable: false },
  { weekDay: 1, enable: true },
  { weekDay: 2, enable: true },
  { weekDay: 3, enable: true },
  { weekDay: 4, enable: true },
  { weekDay: 5, enable: true },
  { weekDay: 6, enable: false }
];
