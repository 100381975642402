
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Base from "../../core/components/Base.vue";
import { Article } from "../model/Article";
import ArticleForm from "./ArticleForm.vue";

@Component({
  components: { ArticleForm }
})
export default class ArticleCard extends Base {
  @Prop() article!: Article;

  isClosed = false;

  getYouTubeId(): string {
    if (this.article.youtubeUrl) {
      const arr = this.article.youtubeUrl.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      return undefined !== arr[2] ? arr[2].split(/[^\w-]/i)[0] : arr[0];
    }
    return "";
  }

  getThumbnail(): string {
    return `https://img.youtube.com/vi/${this.getYouTubeId()}/sddefault.jpg`;
  }

  truncate(value: string, limit: number) {
    return value.length > limit ? `${value.substring(0, limit - 3)}...` : value;
  }
}
