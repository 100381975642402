import _ from "lodash";

export type ValidationRule = (value: unknown) => boolean | string;

export const NotEmptyRule: ValidationRule = (value: unknown) => {
  return value ? true : "Ce champ doit être renseigné";
};

export const NotZeroRule: ValidationRule = (value: unknown) => {
  return value && value !== "0" ? true : "Ce champ doit être renseigné";
};

export const NoCurrencyRule: ValidationRule = (value: unknown) => {
  if (_.isString(value) && (value.includes("€") || value.includes("$") || value.includes("£")))
    return "Inutile d'ajouter le symbole monétaire. (€, $, etc)";
  return true;
};

export const IsValidPriceRule: ValidationRule = (value: unknown) => {
  if (_.isString(value)) {
    if (value && !value.match(/^\d{1,3}([.,]\d{1,2})?$/))
      return "Prix invalide. Exemples de formats valides : 12 ; 8.99 ; 5,20";
  }
  return true;
};

export const LengthRule = (minLength: number, maxLength: number): ValidationRule => (
  value: unknown
) => {
  if (_.isString(value) && value.length >= minLength && value.length <= maxLength) return true;
  return `Doit contenir entre ${minLength} et ${maxLength} caractère${maxLength > 1 ? "s" : ""}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FileSizeRule: ValidationRule = (value: unknown | any) => {
  return value && value.size < 1000000 ? true : "La taille de l'image doit être inférieure à 1 MB";
};

export const NotValidEmailRule: ValidationRule = (value: unknown) => {
  return _.isString(value) && /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)$/.test(value)
    ? true
    : "Ce champ doit être valide";
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ArrayLengthRule: ValidationRule = (value: unknown | any) => {
  return value && value.length >= 1 ? true : "Ce champ doit contenir une valeur au minimum";
};
