import { article } from "@/shared/articles/store";
import { booking } from "@/shared/bookings/store";
import { dbProduct } from "@/shared/db-products/store";
import { media } from "@/shared/medias/store";
import { menu } from "@/shared/menus/store";
import Vue from "vue";
import Vuex from "vuex";
import { core } from "./coreState";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    core,
    menu,
    article,
    booking,
    dbProduct,
    media
  }
});
