/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

export abstract class SecureService {
  client: AxiosInstance;

  constructor(config?: AxiosRequestConfig) {
    let isRefreshing = false;
    let failedQueue: any = [];

    const processQueue = (error: any | null, token: string | null) => {
      failedQueue.forEach((prom: any) => {
        if (error) {
          prom.reject(error);
        } else {
          prom.resolve(token);
        }
      });
      failedQueue = [];
    };

    this.client = axios.create(config);
    this.client.interceptors.request.use(iConfig => {
      const nConfig = iConfig;
      if (localStorage.apikey)
        nConfig.headers = {
          ...nConfig.headers,
          "x-api-key": localStorage.apikey
        };
      if (localStorage.accessToken)
        nConfig.headers = {
          ...nConfig.headers,
          authorization: `Bearer ${localStorage.accessToken}`
        };
      return nConfig;
    });
    this.client.interceptors.response.use(
      response => {
        return response;
      },
      async error => {
        const originalRequest = error.config;

        if (
          error.response.status === 401 ||
          (error.response.status === 406 && !originalRequest._retry)
        ) {
          if (isRefreshing) {
            try {
              const token = await new Promise((resolve, reject) => {
                failedQueue.push({ resolve, reject });
              });
              originalRequest.headers.Authorization = `Bearer ${token}`;
              return await axios(originalRequest);
            } catch (err) {
              return Promise.reject(err);
            }
          }

          originalRequest._retry = true;
          isRefreshing = true;

          const { refreshToken } = localStorage;
          return new Promise((resolve, reject) => {
            axios
              .post(`${process.env.VUE_APP_API_URL}auth/refresh`, { refreshToken })
              .then(({ data }) => {
                localStorage.setItem("accessToken", data.accessToken);
                originalRequest.headers.authorization = `Bearer ${data.accessToken}`;
                processQueue(null, data.token);
                resolve(axios(originalRequest));
              })
              .catch(err => {
                processQueue(err, null);
                window.location.pathname = "/login";
                reject(err);
              })
              .finally(() => {
                isRefreshing = false;
              });
          });
        }
        return Promise.reject(error);
      }
    );
  }
}
